import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { postCreateSwap } from '~/api/swaps';
import { cartItemsAddons } from '~/components/Cart/CartAddonsSection';
import { useModals } from '~/contexts/ModalsContext';
import { useSession } from '~/contexts/SessionContext';
import useAddToCart from '~/hooks/cart/useAddToCart';
import useRequestCheckout from '~/hooks/useRequestCheckout';
import {
  fireInitiatedCheckout,
  visitedCheckoutOffer,
} from '~/services/analytics/events/item';
import { RailsItem } from '~/typings/services/rails/item';

/**
 * Given a RailsItem object, it lets you request a Make Offer or Buy Now.
 * Based on the RailsItem, it will either open the Make Offer modal, or simply navigate
 * to the appropriate checkout application.
 */
export default function usePurchaseItem(
  item: RailsItem,
  selectedVariationId?: string | number,
) {
  const { buyNow, isLoadingBuyNow } = useRequestCheckout(item);
  const { onAddToCart, error: cartError } = useAddToCart(item);
  const { loggedIn, user } = useSession();
  const modals = useModals();
  const queryClient = useQueryClient();

  const error = cartError;

  const onBuyNow = useCallback(
    async (e: React.MouseEvent) => {
      const betaCheckout = (e.ctrlKey || e.metaKey) && e.shiftKey;

      // For testing cart addons (WEB-1739), bail out of the old logic
      if (cartItemsAddons([{ item }]).length > 0) {
        return onAddToCart(selectedVariationId);
      }

      fireInitiatedCheckout(item, loggedIn, true, queryClient);

      if (
        item.variations &&
        item.variations.length > 0 &&
        !selectedVariationId
      ) {
        modals.openModal('makeOffer', {
          betaCheckout,
          action: 'buyNow',
          item,
          selectedVariationId,
        });
        visitedCheckoutOffer(item, true);
        return;
      }

      await buyNow(selectedVariationId, betaCheckout);
    },
    [buyNow, item, loggedIn, selectedVariationId],
  );

  const onMakeOffer = useCallback(
    (e: React.MouseEvent, defaultView: 'login' | 'register' = 'login') => {
      const betaCheckout = (e.ctrlKey || e.metaKey) && e.shiftKey;

      const doMakeOffer = () => {
        fireInitiatedCheckout(item, loggedIn, false, queryClient);

        modals.openModal('makeOffer', {
          action: 'makeOffer',
          item,
          selectedVariationId,
          betaCheckout,
        });
        visitedCheckoutOffer(item, false);
      };

      if (!loggedIn) {
        modals.openModal('loginRegister', {
          defaultView,
          skipOnboarding: true,
          contextMessage: 'Log in to make an offer',
          onSuccessfulLogin: doMakeOffer,
          src: 'item',
        });
        return;
      }

      doMakeOffer();
    },
    [item, loggedIn, selectedVariationId],
  );

  const onMessageSeller = useCallback(async () => {
    const doMessageSeller = async () => {
      const { id } = await postCreateSwap(item.id);
      window.location.assign(`/swaps/${id}`);
    };

    const doVerifyMessageSeller = () => {
      if (user?.phone_verified || user?.email_verified) {
        doMessageSeller();
      } else {
        modals.openModal('sendMessageVerifyPhone', {
          allowAutoSuccess: true,
          onVerificationSuccess: doMessageSeller,
        });
      }
    };

    if (!loggedIn) {
      modals.openModal('loginRegister', {
        defaultView: 'login',
        skipOnboarding: true,
        contextMessage: `Log in to message @${item.seller.username}`,
        onSuccessfulLogin: doVerifyMessageSeller,
        src: 'item',
      });
      return;
    }

    doVerifyMessageSeller();
  }, [loggedIn, item, user?.phone_verified, user?.email_verified]);

  return {
    error,
    isLoadingBuyNow,
    onBuyNow,
    onMakeOffer,
    onMessageSeller,
  };
}
