import { OfferByItemId } from '~/api/swaps';
import { RailsItem } from '~/typings/services/rails/item';
import { RailsOffer, RailsSwapDetail } from '~/typings/services/rails/swaps';
import getCurrentOffer from '~/utils/getCurrentOffer';

export function getItemMargin(item: RailsItem | RailsSwapDetail['item']) {
  if (!item.seller_total_cost) {
    return 0;
  }

  return Math.round(((item.price - item.seller_total_cost) / item.price) * 100);
}

export default function getSwapMargin(
  swapDetails: RailsSwapDetail,
  customOffer?: RailsOffer | OfferByItemId,
) {
  const currentOffer = customOffer || getCurrentOffer(swapDetails);
  if (!currentOffer) {
    return 0;
  }

  const offerPrice =
    'amount' in currentOffer
      ? currentOffer.amount
      : currentOffer.itemization.price;

  if (
    !currentOffer ||
    offerPrice == null ||
    swapDetails.item.seller_total_cost == null
  ) {
    return 0;
  }

  return Math.round(
    ((offerPrice - swapDetails.item.seller_total_cost) / offerPrice) * 100,
  );
}
