import useUserConfiguration from '~/hooks/useUserConfiguration';
import { RailsSessionUser } from '~/typings/services/rails/session';

export default function useHasRailsSetting<
  T extends keyof RailsSessionUser['settings'],
>(key: T) {
  const { data } = useUserConfiguration();
  const settingValue = data?.settings?.[key];
  return !!settingValue;
}
