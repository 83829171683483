import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { fetchItemById } from '~/api/item';
import QuickAddToCart from '~/components/Cart/QuickAddToCart';
import ItemThumbnail from '~/components/ItemThumbnail/ItemThumbnail';
import { GuestCartItem } from '~/hooks/cart/useAddToCart';
import { useCart } from '~/hooks/cart/useCart';
import { addedCartAddon } from '~/services/analytics/events/carts';
import { CartItem } from '~/typings/services/rails/cart';
import { RailsItem } from '~/typings/services/rails/item';
import { minutes30inMS } from '../../constants/times';

const HARDCODED_ADDONS: {
  category_1: string;
  category_2?: string;
  items: number[];
}[] = [];

export function cartItemsAddons(
  cartItems?: ({ item: RailsItem } | CartItem | GuestCartItem)[],
) {
  if (!cartItems) {
    return [];
  }
  const itemIds = new Set<number>();

  for (let i = 0; i < cartItems.length; i++) {
    const item = cartItems[i].item;

    for (let j = 0; j < HARDCODED_ADDONS.length; j++) {
      const addon = HARDCODED_ADDONS[j];
      let shouldAddTheseAddons = false;

      if (addon.category_2) {
        if (
          item.category_1 === addon.category_1 &&
          item.category_2 === addon.category_2
        ) {
          shouldAddTheseAddons = true;
        }
      } else if (addon.category_1) {
        if (item.category_1 === addon.category_1) {
          shouldAddTheseAddons = true;
        }
      }

      if (shouldAddTheseAddons) {
        for (let k = 0; k < addon.items.length; k++) {
          itemIds.add(addon.items[k]);
        }
      }
    }
  }

  return Array.from(itemIds);
}

interface Props {}

const CartAddonsSection: React.FC<Props> = () => {
  const { data: cart, refetch } = useCart();
  const cartMatches = useMemo(() => cartItemsAddons(cart?.cart_items), [cart]);

  const { data: matchedItems } = useQuery(
    ['matched-items', cartMatches],
    () => Promise.all(cartMatches.map(id => fetchItemById(id))),
    {
      enabled: cartMatches.length > 0,
      staleTime: minutes30inMS,
      select: data =>
        data.filter(
          item =>
            item.state === 'available' && item.actions.includes('add_to_cart'),
        ),
    },
  );

  if (!matchedItems || matchedItems.length === 0) {
    return null;
  }

  return (
    <>
      <h1 className="mb-2 mt-8 text-xl font-semibold">
        You might also need...
      </h1>
      <p className="text-slate-green-500 mb-2 text-sm">
        Free shipping on all add-on items
      </p>

      <div className="grid grid-cols-2 gap-2 md:grid-cols-3">
        {matchedItems?.map(item => (
          <ItemThumbnail
            key={item.id}
            item={item}
            additionalLowerSection={
              <QuickAddToCart
                item={item}
                onComplete={() => {
                  addedCartAddon(item);
                  refetch();
                }}
              />
            }
          />
        ))}
      </div>
    </>
  );
};

export default CartAddonsSection;
