'use client';

import createSvg from './createSvg';

export const ProfileShippingIcon = createSvg(
  ({ height, width, fill = 'currentColor', ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M13.333 5.333h-2V2.667H2c-.733 0-1.333.6-1.333 1.333v7.333H2c0 1.107.893 2 2 2s2-.893 2-2h4c0 1.107.893 2 2 2s2-.893 2-2h1.333V8l-2-2.667zm-9.333 7c-.553 0-1-.446-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .554-.447 1-1 1zm9-6L14.307 8h-2.974V6.333H13zm-1 6c-.553 0-1-.446-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .554-.447 1-1 1z"
      />
    </svg>
  ),
);

export const ProfileLocationIcon = createSvg(({ height, width, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 1.333A4.663 4.663 0 0 0 3.333 6C3.333 9.5 8 14.667 8 14.667S12.667 9.5 12.667 6A4.663 4.663 0 0 0 8 1.333zm0 6.334a1.667 1.667 0 1 1 .001-3.335A1.667 1.667 0 0 1 8 7.667z"
    />
  </svg>
));

export const ProfileLastActiveIcon = createSvg(
  ({ height, width, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M7.993 1.333A6.663 6.663 0 0 0 1.333 8c0 3.68 2.98 6.667 6.66 6.667A6.67 6.67 0 0 0 14.667 8a6.67 6.67 0 0 0-6.674-6.667zm.007 12A5.332 5.332 0 0 1 2.667 8 5.332 5.332 0 0 1 8 2.667 5.332 5.332 0 0 1 13.333 8 5.332 5.332 0 0 1 8 13.333z" />
        <path d="M8.333 4.667h-1v4l3.5 2.1.5-.82-3-1.78z" />
      </g>
    </svg>
  ),
);

export const ProfileMemberSinceIcon = createSvg(
  ({ height, width, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.333 5.333a2.666 2.666 0 1 0 5.334 0 2.666 2.666 0 1 0-5.334 0zm-2.666 6v2h10.666v-2C13.333 9.56 9.78 8.667 8 8.667c-1.78 0-5.333.893-5.333 2.666z"
      />
    </svg>
  ),
);
