import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Button } from 'ui';
import { addToCart } from '~/api/carts';
import useInCart from '~/hooks/cart/useInCart';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';

interface Props {
  item: RailsThumbnailItem | RailsItem;
  onComplete?: () => void;
}

const QuickAddToCart: React.FC<Props> = ({ item, onComplete }) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(() => addToCart(item.id), {
    onSuccess: data => {
      queryClient.setQueryData(['cart'], data);
      onComplete?.();
    },
  });
  const inCart = useInCart(item.id);

  if (!item.actions?.includes('add_to_cart')) {
    return null;
  }

  if (inCart) {
    return <div className="mt-2 text-center font-semibold">Item in cart</div>;
  }

  return (
    <Button
      className="mt-2"
      color="darkgrey"
      fluid
      size="small"
      onClick={() => mutate()}
      disabled={isLoading}
    >
      Add to Cart
    </Button>
  );
};

export default QuickAddToCart;
