import {
  QueryClient,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { NextPageContext } from 'next';
import { fetchAddresses } from '~/api/address';
import { useSession } from '~/contexts/SessionContext';
import { RailsAddress } from '~/typings/services/rails/session';

export function prefetchAddresses(
  ctx: NextPageContext,
  queryClient: QueryClient,
) {
  return queryClient.fetchQuery(['addresses'], fetchAddresses.bind(ctx));
}

export default function useAddresses(
  options?: UseQueryOptions<RailsAddress[]>,
): UseQueryResult<RailsAddress[]> {
  const { loggedIn } = useSession();
  return useQuery<RailsAddress[]>(['addresses'], fetchAddresses, {
    enabled: loggedIn,
    staleTime: 1000 * 60 * 60 * 24,
    ...options,
  });
}
