import { clsx } from 'clsx';
import React from 'react';
import HelpIcon from '~/public/static/svg/HelpIcon';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'large';
};

const HelpButton: React.FC<Props> = ({ className, size, ...props }) => (
  <button
    aria-label="Help"
    className={clsx(className, {
      '-m-2 p-2': !size,
      '-m-4 p-4': size === 'large',
    })}
    type="button"
    tabIndex={-1}
    {...props}
  >
    <HelpIcon
      style={{
        height: size === 'large' ? 16 : undefined,
        width: size === 'large' ? 16 : undefined,
      }}
    />
  </button>
);

export default HelpButton;
