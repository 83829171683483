/**
 * Hook to determine if an item by ID is in your cart
 * @param itemId
 */
import { useMemo } from 'react';
import { useCart } from '~/hooks/cart/useCart';

export default function useInCart(itemId?: number) {
  const { data: cart } = useCart();

  return useMemo(() => {
    if (!itemId) {
      return false;
    }

    return !!cart?.cart_items?.find(ci => ci.item.id === itemId);
  }, [cart?.cart_items?.length, itemId]);
}
