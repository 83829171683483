import { useCallback, useEffect, useState } from 'react';
import { qsStringify } from 'utils';
import { postCreateSwap } from '~/api/swaps';
import { useModals } from '~/contexts/ModalsContext';
import { useSession } from '~/contexts/SessionContext';
import useAddress from '~/hooks/useAddress';
import { RailsItem } from '~/typings/services/rails/item';

export function getCheckoutUrl(beta?: boolean) {
  if (beta) {
    return '/checkoutv2';
  }
  return '/checkout';
}

export default function useRequestCheckout(item: RailsItem) {
  const { loggedIn } = useSession();
  const modals = useModals();
  const { data: address } = useAddress('shipTo');
  const [isLoadingBuyNow, setIsLoadingBuyNow] = useState(false);

  useEffect(() => {
    if (isLoadingBuyNow) {
      setIsLoadingBuyNow(false);
    }
  }, [item.id]);

  const buyNow = useCallback(
    async (selectedVariationId?: string | number, betaCheckout?: boolean) => {
      async function goToCheckout(
        selectedVariationId: string | number | undefined,
      ) {
        setIsLoadingBuyNow(true);
        const { id } = await postCreateSwap(item.id);

        window.location.assign(
          `${getCheckoutUrl(betaCheckout)}${qsStringify({
            swapId: id,
            offerType: 'buy',
            variationId: selectedVariationId,
            ship_to_address_id: address?.id,
          })}`,
        );
      }

      if (loggedIn) {
        await goToCheckout(selectedVariationId);
      } else {
        modals.openModal('loginRegister', {
          onSuccessfulLogin: async () => {
            await goToCheckout(selectedVariationId);
          },
          contextMessage: 'Create an account to purchase',
          skipOnboarding: true,
        });
      }
    },
    [item.id, loggedIn, modals, address?.id],
  );

  const makeOffer = useCallback(
    async (
      offerAmount: number,
      selectedVariationId?: string | number,
      betaCheckout?: boolean,
    ) => {
      const { id } = await postCreateSwap(
        item.id,
        offerAmount,
        selectedVariationId,
      );

      window.location.assign(
        `${getCheckoutUrl(betaCheckout)}${qsStringify({
          offerAmount,
          swapId: id,
          offerType: 'offer',
          variationId: selectedVariationId,
          ship_to_address_id: address?.id,
        })}`,
      );
    },
    [item, address?.id],
  );

  const acceptOffer = useCallback(
    async (
      type: 'counterOffer' | 'sellerOffer',
      selectedVariationId?: string | number,
      betaCheckout?: boolean,
    ) => {
      async function goToCheckout(
        selectedVariationId: string | number | undefined,
      ) {
        setIsLoadingBuyNow(true);
        const { id } = await postCreateSwap(item.id);

        window.location.assign(
          `${getCheckoutUrl(betaCheckout)}${qsStringify({
            swapId: id,
            offerType: type,
            variationId: selectedVariationId,
            ship_to_address_id: address?.id,
          })}`,
        );
      }

      if (loggedIn) {
        await goToCheckout(selectedVariationId);
      } else {
        modals.openModal('loginRegister', {
          onSuccessfulLogin: async () => {
            await goToCheckout(selectedVariationId);
          },
          contextMessage: 'Create an account to accept the offer',
          skipOnboarding: true,
        });
      }
    },
    [item.id, loggedIn, modals, address?.id],
  );

  return {
    acceptOffer,
    buyNow,
    makeOffer,
    isLoadingBuyNow,
  };
}
