import { differenceInDays, format } from 'date-fns';
import React from 'react';
import Tooltip from '~/components/Form/Tooltip';
import HelpButton from '~/components/Links/HelpButton';
import {
  ProfileLastActiveIcon,
  ProfileLocationIcon,
  ProfileMemberSinceIcon,
  ProfileShippingIcon,
} from '~/public/static/svg/ProfileIcons';
import { RailsProfile } from '~/typings/services/rails/profiles';

function lastActive(date: string) {
  const now = Date.now();

  const dayDiff = Math.abs(differenceInDays(new Date(date), now));
  if (dayDiff === 0) {
    return 'Today';
  }

  if (dayDiff === 1) {
    return 'Yesterday';
  }

  if (dayDiff > 31) {
    return 'Over a month ago';
  }

  return `${dayDiff} days ago`;
}

function shipDays(days: number) {
  if (days <= 1) {
    return 'Within 1 business day';
  }

  if (days <= 5) {
    return `${Math.abs(days)} business days`;
  }

  if (days > 5) {
    return '6+ business days';
  }
}

interface Props {
  white?: boolean;
  profile: RailsProfile;
  ignore?: 'estimated'[];
}

const UserProfileBullets: React.FC<Props> = ({ ignore, profile, white }) => {
  const rowCx = `flex items-center space-x-2 ${
    white ? 'text-white' : 'text-slate-green-500'
  }`;

  const elementCx = `font-semibold ${
    white ? 'text-white' : 'text-slate-green-500'
  }`;

  return (
    <>
      {!ignore?.includes('estimated') && profile.ship_days != null && (
        <div className={rowCx}>
          <div>
            <ProfileShippingIcon />
          </div>

          <div>
            Estimated Time to Ship:{' '}
            <b className={`mr-2 ${elementCx}`}>{shipDays(profile.ship_days)}</b>
            <Tooltip
              direction="top"
              tooltip="This is an estimate of how long it will take the seller to place your item in the mail, based on their selling history. Delivery time will vary by distance and carrier, but for most domestic orders items are delivered 1-3 days after they are shipped."
              width={200}
            >
              <HelpButton />
            </Tooltip>
          </div>
        </div>
      )}

      {!!(profile.ships_from_state || profile.ships_from_country) && (
        <div className={rowCx}>
          <div>
            <ProfileLocationIcon />
          </div>

          <div>
            Ships From:{' '}
            <b className={elementCx}>
              {profile.ships_from_state}, {profile.ships_from_country}
            </b>
          </div>
        </div>
      )}

      {!!profile.last_active_at && (
        <div className={rowCx}>
          <div>
            <ProfileLastActiveIcon />
          </div>

          <div>
            Last Active:{' '}
            <b className={elementCx}>{lastActive(profile.last_active_at)}</b>
          </div>
        </div>
      )}

      {!!profile.joined_at && (
        <div className={rowCx}>
          <div>
            <ProfileMemberSinceIcon />
          </div>

          <div>
            Member Since:{' '}
            <b className={elementCx}>
              {format(new Date(profile.joined_at), 'MMMM yyyy')}
            </b>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfileBullets;
