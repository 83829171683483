import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addToCart } from '~/api/carts';
import { generateLoginLink } from '~/components/Links/LoginLink';
import { useModals } from '~/contexts/ModalsContext';
import { useSession } from '~/contexts/SessionContext';
import { addLocalStorageCartItem } from '~/hooks/cart/useCart';
import useInCart from '~/hooks/cart/useInCart';
import { fireAddedToCart } from '~/services/analytics/events/item';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';

export interface GuestCartItem {
  id: number;
  item: RailsItem | RailsThumbnailItem;
}

export default function useAddToCart(item?: RailsItem): {
  error: any;
  isLoading: boolean;
  isSuccess: boolean;
  inCart: boolean;
  onAddToCart: (selectedVariationId?: string | number) => void;
  onAddToCartAsync: (selectedVariationId?: string | number) => Promise<void>;
} {
  const { loggedIn } = useSession();
  const modals = useModals();
  const queryClient = useQueryClient();

  const inCart = useInCart(item?.id);

  const {
    mutate: onAddToCart,
    mutateAsync: onAddToCartAsync,
    error,
    isLoading,
    isSuccess,
  } = useMutation(async (selectedVariationId?: string | number) => {
    if (!item) {
      return;
    }
    if (loggedIn) {
      if (
        item.variations &&
        item.variations.length > 0 &&
        !selectedVariationId
      ) {
        modals.openModal('makeOffer', {
          item,
          selectedVariationId,
          action: 'addToCart',
        });
        return;
      }

      await addToCart(item.id, selectedVariationId);
      fireAddedToCart(item, queryClient);
      window.location.assign('/cart');
      return;
    }

    if (!localStorage) {
      window.location.assign(
        generateLoginLink({
          destination: window.location.pathname,
          message: 'Log in to add this item to your cart',
        }),
      );
      return;
    }

    try {
      addLocalStorageCartItem(item);
      window.location.assign('/cart');
    } catch (e) {}
  });

  return {
    error,
    isLoading,
    isSuccess,
    inCart,
    onAddToCart,
    onAddToCartAsync,
  };
}
