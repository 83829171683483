import { Transition } from '@headlessui/react';
import React from 'react';
import BaseNewModal, {
  BaseNewModalProps,
} from '~/components/NewModal/BaseNewModal';
import NewModalBody, {
  NewModalBodyProps,
} from '~/components/NewModal/NewModalBody';
import { NewModalContext } from '~/contexts/NewModalContext';

export interface SimpleModalProps
  extends BaseNewModalProps,
    Omit<NewModalBodyProps, 'className'> {
  bodyClassName?: NewModalBodyProps['className'];
  onFinishedClosing?: () => void;
}

const SimpleModal: React.FC<React.PropsWithChildren<SimpleModalProps>> = ({
  bodyClassName,
  children,
  title,
  description,
  open,
  onClose,
  onFinishedClosing,
  ...props
}) => (
  <NewModalContext.Provider value={{ open, onClose }}>
    <Transition
      show={open}
      as={React.Fragment}
      appear
      afterLeave={onFinishedClosing}
    >
      <BaseNewModal {...props} open={open} onClose={onClose}>
        <NewModalBody
          title={title}
          description={description}
          className={bodyClassName}
        >
          {children}
        </NewModalBody>
      </BaseNewModal>
    </Transition>
  </NewModalContext.Provider>
);

export default SimpleModal;
