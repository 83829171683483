import { useMobile } from 'common-nextjs';
import React from 'react';
import FeatheredOverflowContainer from '~/components/FeatheredOverflowContainer';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

/**
 * Feathers on mobile, does nothing special on desktop
 */
const MobileOnlyFeatheredOverflowContainer = React.forwardRef<
  HTMLDivElement,
  Props
>(({ children, className }, ref) => {
  const mobile = useMobile();

  if (mobile) {
    return (
      <FeatheredOverflowContainer
        className={`md:overflow-visible ${className}`}
        ref={ref}
      >
        {children}
      </FeatheredOverflowContainer>
    );
  }

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
});

export default MobileOnlyFeatheredOverflowContainer;
