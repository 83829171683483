import { useQuery } from '@tanstack/react-query';
import Link from 'next/link';
import React from 'react';
import { Loader } from 'ui';
import { displayPrice, thousandsSeparator } from 'utils';
import { fetchTradeInValueGuide } from '~/api/valueGuide';
import { itemDetailsToSearchLink } from '~/hooks/itemDetailsToSearchLink';
import { useListFlowDetails } from '~/hooks/listFlow/useListFlowDetails';
import { RailsFacetQueryOptions } from '~/typings/services/rails/facets';
import { RailsItem } from '~/typings/services/rails/item';
import { RailsSwapDetail } from '~/typings/services/rails/swaps';

interface Props {
  item: RailsItem | RailsSwapDetail['item'];
}

const ItemValueGuideInsights: React.FC<Props> = ({ item }) => {
  const { data: itemCategoryDetails } = useListFlowDetails(
    item?.categories?.[0]?.id,
  );

  const { data: valueGuide, isLoading: isValueGuideLoading } = useQuery(
    ['value-guide', item.id],
    () => {
      const query: RailsFacetQueryOptions = {};

      if (item.categories?.length > 0) {
        query.category = [item.categories[0].id];
      }

      if (item.details) {
        for (const itemDetailGroup of item.details) {
          const masterDetail = itemCategoryDetails?.find(
            d => d.slug === itemDetailGroup.slug,
          );

          if (
            !itemDetailGroup.values ||
            !masterDetail ||
            !masterDetail.value_guides_required
          ) {
            continue;
          }

          query[itemDetailGroup.slug] = itemDetailGroup.values.map(
            value => value.id,
          );
        }
      }

      if (item.model) {
        query.model = [item.model.id];
      }

      return fetchTradeInValueGuide(query);
    },
    {
      enabled: !!itemCategoryDetails,
      select: d => d.meta.value_guide,
    },
  );

  const sellThroughRate =
    valueGuide?.recently_published_items != null &&
    valueGuide?.recently_published_items > 0
      ? Math.round(
          ((valueGuide?.recent_sales ?? 0) /
            valueGuide?.recently_published_items) *
            100,
        )
      : 0;

  return (
    <Loader loading={isValueGuideLoading || !valueGuide}>
      <div className="space-y-2">
        <div>
          <b>Your Price</b>: {displayPrice(item.price)}{' '}
          <span>
            ({displayPrice(item.shipping_discount_amount || 0)} shipping
            discount)
          </span>
        </div>

        <div>
          <b>Avg Sales Price</b>: {displayPrice(valueGuide?.statistics?.median)}
        </div>

        <div>
          <b>Suggested Price</b>:{' '}
          {displayPrice(
            (valueGuide?.statistics?.median || 0) +
              (item.shipping_discount_amount || 0),
          )}
          <div className="text-xs">Includes shipping discount</div>
        </div>

        <div>
          <div>
            <b>Avg Time to Sell</b>:{' '}
            {valueGuide?.time_to_sell ? (
              <>
                {Math.round(
                  (valueGuide.time_to_sell.dom_0_30 /
                    valueGuide.time_to_sell.total_items) *
                    100,
                ) || '--'}
                {'% / '}
                {Math.round(
                  (valueGuide.time_to_sell.dom_30_90 /
                    valueGuide.time_to_sell.total_items) *
                    100,
                ) || '--'}
                {'% / '}
                {Math.round(
                  (valueGuide.time_to_sell.dom_90 /
                    valueGuide.time_to_sell.total_items) *
                    100,
                ) || '--'}
                %
              </>
            ) : (
              'N/A'
            )}
          </div>
          (&lt;30 / 30-90 / 90+)
        </div>

        <div>
          <b>Sell-through Rate</b>: {sellThroughRate || 0}%
        </div>

        <div>
          <b>Recent Sales</b>:{' '}
          <Link
            className="underline"
            href={itemDetailsToSearchLink(item, {
              recently_sold: 1,
            })}
          >
            {thousandsSeparator(valueGuide?.recent_sales) || 0}
          </Link>
        </div>

        <div>
          <b>Currently Available</b>:{' '}
          <Link className="underline" href={itemDetailsToSearchLink(item)}>
            {thousandsSeparator(valueGuide?.available_items) || 0}
          </Link>
        </div>
      </div>
    </Loader>
  );
};

export default ItemValueGuideInsights;
