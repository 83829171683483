import fireAnalytics from '~/services/analytics/fireAnalytics';
import getUTMsFromCookies from '~/services/analytics/getUTMsFromCookies';
import { Cart } from '~/typings/services/rails/cart';
import { sum } from 'utils';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';

export const fireVisitedCart = (count: number, displaysAddons?: boolean) =>
  fireAnalytics('Visited Cart', null, {
    count,
    displayed_addons: displaysAddons,
  });

export const fireSavedForLater = (itemId: number) =>
  fireAnalytics('Saved for Later', null, { item_id: itemId });

export const fireRemovedFromCart = (itemId: number) =>
  fireAnalytics('Removed from Cart', null, { item_id: itemId });

export const fireCartInitiatedCheckout = (cart: Cart) => {
  const total = sum(cart.cart_items.map(ci => ci.item.price));

  fireAnalytics('Initiated Checkout', null, {
    ...getUTMsFromCookies(),
    total,
    buy_now: false,
    cart: true,
    cart_id: cart.id,
    item_id: cart.cart_items.map(ci => ci.item.id),
    platform: 'WEB',
    guest_checkout: false,
    has_address: !!cart.ship_to_address,
    has_payment: !!cart.payment_method,
    list_price: total,
  });
};

export const addedCartAddon = (item: RailsItem | RailsThumbnailItem) =>
  fireAnalytics('Added Add On', null, {
    item_id: item.id,
    category_1: item.category_1,
    category_2: item.category_2,
  });
