import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchListFlowDetails } from '~/api/listFlow';
import { minutes30inMS } from '../../constants/times';
import { RailsDetail } from '~/typings/services/rails/item';
import { UseQueryResult } from '@tanstack/react-query';

/**
 *
 * @param queryClient Should be the shared query client
 * @param categoryId
 */
export function prefetchListFlowDetails(
  queryClient: QueryClient,
  categoryId: number | string,
) {
  return queryClient.fetchQuery(
    ['list-flow-details', categoryId],
    () => fetchListFlowDetails(categoryId),
    {
      staleTime: minutes30inMS,
    },
  );
}

export function useListFlowDetails(
  categoryId?: number | string,
  options?: UseQueryOptions<RailsDetail[]>,
): UseQueryResult<RailsDetail[]> {
  return useQuery<RailsDetail[]>(
    ['list-flow-details', categoryId],
    () => fetchListFlowDetails(categoryId!),
    {
      enabled: !!categoryId,
      staleTime: minutes30inMS,
      ...options,
    },
  );
}
