import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from '@heroicons/react/24/outline';
import { Table, flexRender } from '@tanstack/react-table';
import { Renderable } from '@tanstack/react-table/src';
import { clsx } from 'clsx';
import React from 'react';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeader,
} from './TableElements';

// Ran into issues with TypeScript 5.3.2 and the flexRender function
function patchedFlexRender<TProps extends object>(
  Comp: Renderable<TProps>,
  props: TProps,
): any {
  return flexRender(Comp, props);
}

interface Props {
  table: Table<any>;
}

const SimpleReactTable: React.FC<Props> = ({ table }) => {
  const footer = table.getFooterGroups();

  const sortIconCx = 'text-green-500 ml-1.5 h-4 w-4 inline-block';

  return (
    <StyledTable>
      <thead className="border-grey-200 border-b">
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <StyledTableHeader
                key={header.id}
                colSpan={header.colSpan}
                onClick={() => {
                  if (header.column.getCanSort()) {
                    header.column.toggleSorting();
                  }
                }}
                style={{
                  width: header.column.columnDef.size,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : patchedFlexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {{
                  asc: <BarsArrowUpIcon className={sortIconCx} />,
                  desc: <BarsArrowDownIcon className={sortIconCx} />,
                }[header.column.getIsSorted() as string] ?? null}
              </StyledTableHeader>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr
            key={row.id}
            className={clsx('transition-colors duration-75', {
              'hover:bg-grey-100': !row.getIsSelected(),
              'bg-green-200': row.getIsSelected(),
              'bg-grey-50': !row.getIsSelected() && index % 2 === 0,
            })}
          >
            {row.getVisibleCells().map(cell => (
              <StyledTableCell
                key={cell.id}
                style={{
                  width: cell.column.columnDef.size,
                }}
              >
                {patchedFlexRender(
                  cell.column.columnDef.cell,
                  cell.getContext(),
                )}
              </StyledTableCell>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        {footer.map(footerGroup => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map(footer => (
              <StyledTableHeader
                key={footer.id}
                colSpan={footer.colSpan}
                style={{
                  width: footer.column.columnDef.size,
                }}
              >
                {patchedFlexRender(
                  footer.column.columnDef.footer,
                  footer.getContext(),
                )}
              </StyledTableHeader>
            ))}
          </tr>
        ))}
      </tfoot>
    </StyledTable>
  );
};

export default SimpleReactTable;
