import React from 'react';
import { clsx } from 'clsx';

export interface PanelProps extends React.BaseHTMLAttributes<HTMLDivElement> {
  padding?: boolean;
}

export const Panel: React.FC<PanelProps> = ({
  children,
  className,
  padding = true,
  ...props
}) => (
  <div
    className={clsx(
      'border-grey-200 rounded border shadow-md',
      className,
      padding && 'px-4 py-3',
    )}
    {...props}
  >
    {children}
  </div>
);
