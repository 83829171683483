import React from 'react';
import ItemFreeShippingPill from '~/components/ItemThumbnail/ItemFreeShippingPill';
import { RailsItem } from '~/typings/services/rails/item';
import { RailsSwapDetail } from '~/typings/services/rails/swaps';

interface Props {
  item: RailsItem | RailsSwapDetail['item'];
}

const FreeShippingItemDetailsPill: React.FC<Props> = ({ item }) => {
  if (!item.shipping_label || item.shipping_label.slug !== 'free_shipping') {
    return null;
  }

  return <ItemFreeShippingPill />;
};

export default FreeShippingItemDetailsPill;
