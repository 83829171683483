import {
  RailsFacetResponse,
  RailsTradeInValueGuideResponse,
  RailsValueGuideResponse,
} from '~/typings/services/rails/responses';
import { RailsFacetQueryOptions } from '~/typings/services/rails/facets';
import {
  RailsCategory,
  RailsDetail,
  RailsItemModel,
} from '~/typings/services/rails/item';
import { fetcher, makeFetcher } from 'fetcher-session';

export const fetchValueGuideDetails = makeFetcher<
  RailsDetail[],
  [number | string]
>((categoryId: number | string) => [
  '/v1/value_guide/details',
  {
    params: {
      category_id: categoryId,
    },
  },
]);

export const fetchValueGuideModels = makeFetcher<
  RailsItemModel[],
  [categoryId?: number | string, brandId?: number | string]
>((categoryId: number | string, brandId: number | string) => [
  '/v1/models',
  {
    params: {
      category_id: categoryId,
      brand_id: brandId,
    },
  },
]);

export const fetchNewValueGuideCalculation = (values: RailsFacetQueryOptions) =>
  fetcher<RailsValueGuideResponse>('/v1/value_guide', {
    dataOnly: false,
    params: {
      ...values,
      state: ['sold'],
      page_size: 48,
      context: 'value_guide',
    },
  });

export const fetchTradeInValueGuide = (values: RailsFacetQueryOptions) =>
  fetcher<RailsTradeInValueGuideResponse>('/v2/value_guide', {
    dataOnly: false,
    params: {
      ...values,
      platform: 'sidelineswap',
      context: 'trade_in',
    },
  });

export const postSaveForLater = makeFetcher<
  RailsFacetResponse,
  [
    {
      price?: number | string;
      category?: RailsCategory | null;
      brand?: RailsDetail | null;
      model?: RailsItemModel | null;
      condition?: RailsDetail | null;
    },
  ]
>(({ price, category, brand, model, condition }) => [
  '/v1/items',
  {
    method: 'post',
    body: {
      item: {
        name: `${brand?.name ?? ''} ${model?.name ?? ''} ${
          category?.title_name ?? ''
        }`.replace(/ {2}/g, ' '),
        category_ids: [category?.id].filter(Boolean),
        details: [brand?.slug, condition?.slug].filter(Boolean),
        model_id: model?.id,
        list_price: price,
      },
    },
  },
]);
