import { clsx } from 'clsx';
import React from 'react';

interface StyledTableProps
  extends React.TableHTMLAttributes<HTMLTableElement> {}

export const StyledTable: React.FC<StyledTableProps> = ({
  children,
  ...props
}) => (
  <div className="overflow-x-auto">
    <table {...props} className="min-w-full">
      {children}
    </table>
  </div>
);

// table th

interface StyledTableHeaderProps
  extends React.ThHTMLAttributes<HTMLTableCellElement> {}

export const StyledTableHeader: React.FC<StyledTableHeaderProps> = ({
  children,
  ...props
}) => (
  <th
    scope="col"
    {...props}
    className="whitespace-nowrap px-3 py-2 text-left text-sm font-semibold first:pl-2 last:pr-2"
  >
    {children}
  </th>
);

// table tr

interface StyledTableRowProps
  extends React.TdHTMLAttributes<HTMLTableRowElement> {}

export const StyledTableRow: React.FC<StyledTableRowProps> = ({
  children,
  ...props
}) => (
  <tr
    {...props}
    className="hover:bg-grey-100 odd:bg-grey-50 transition-colors duration-75"
  >
    {children}
  </tr>
);

// table td

interface StyledTableCellProps
  extends React.TdHTMLAttributes<HTMLTableCellElement> {}

export const StyledTableCell: React.FC<StyledTableCellProps> = ({
  children,
  className,
  ...props
}) => (
  <td
    {...props}
    className={clsx(
      'whitespace-nowrap px-3 py-2 text-sm first:pl-2 last:pr-2',
      className,
    )}
  >
    {children}
  </td>
);
