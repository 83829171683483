import { useQuery } from '@tanstack/react-query';
import { formatDistanceToNow } from 'date-fns';
import React, { useState } from 'react';
import { Panel } from 'ui';
import { displayPrice } from 'utils';
import { fetchOffersByItemId } from '~/api/swaps';
import ItemValueGuideInsights from '~/components/ItemDetails/ItemValueGuideInsights';
import NewModalCloseActionButton from '~/components/NewModal/NewModalCloseActionButton';
import SimpleModal from '~/components/NewModal/SimpleModal';
import ItemOffersTable from '~/components/SwapDetails/Information/ItemOffersTable';
import useHasRailsSetting from '~/hooks/useHasRailsSetting';
import { RailsItem } from '~/typings/services/rails/item';
import { RailsSwapDetail } from '~/typings/services/rails/swaps';
import getCurrentOffer from '~/utils/getCurrentOffer';
import getSwapMargin from '~/utils/getSwapMargin';

export default function LegacyInsights({
  asChild,
  children,
  item,
  swapDetails,
}: {
  asChild?: boolean;
  children?: React.ReactNode;
  swapDetails?: RailsSwapDetail;
  item: RailsItem | RailsSwapDetail['item'];
}) {
  const [open, setOpen] = useState(false);
  const isTradeInAccount = useHasRailsSetting('trade_in_account');
  const currentOffer = getCurrentOffer(swapDetails);

  const { data: offers } = useQuery(
    ['offers', item.id],
    () => fetchOffersByItemId(item.id),
    {
      enabled: isTradeInAccount && open,
    },
  );

  if (!isTradeInAccount) {
    return null;
  }

  const margin = swapDetails ? getSwapMargin(swapDetails) : null;
  const itemPublishedAt = item.published_at
    ? new Date(item.published_at)
    : undefined;

  const itemLastBumpedAt = item.last_bumped_at
    ? new Date(item.last_bumped_at)
    : undefined;

  const itemLastPriceDroppedAt = item.last_price_dropped_at
    ? new Date(item.last_price_dropped_at)
    : undefined;

  function handleOpen() {
    return setOpen(true);
  }

  return (
    <>
      {asChild ? (
        React.Children.map(children, child =>
          React.cloneElement(child as React.ReactElement, {
            onClick: handleOpen,
          }),
        )
      ) : (
        <button
          className="text-sm font-normal underline"
          type="button"
          onClick={handleOpen}
        >
          {children}
        </button>
      )}

      <SimpleModal
        title={
          swapDetails ? 'Advanced Swap Insights' : 'Advanced Item Insights'
        }
        open={open}
        onClose={() => setOpen(false)}
        size={offers && offers.length > 0 ? 'xl' : 'md'}
        bodyClassName="space-y-6"
      >
        <Panel>
          <div className="mb-4 text-lg font-semibold">Offer Details</div>

          <div className="space-y-2">
            {swapDetails && (
              <>
                <div>
                  <b>Swap ID</b>: {swapDetails.id}
                </div>

                <div>
                  <b>Offer</b>: {displayPrice(currentOffer?.itemization?.price)}{' '}
                  {margin ? `(${margin}% margin)` : ''}
                </div>
              </>
            )}

            <div>
              <div>
                <b>Recent Offers:</b>
              </div>

              <ItemOffersTable item={item} swapDetails={swapDetails} />
            </div>
          </div>
        </Panel>

        <Panel>
          <div className="mb-4 text-lg font-semibold">Item Details</div>

          <div className="space-y-2">
            <div>
              <b>Event Name</b>: {item.event_name || <i>N/A</i>}
            </div>

            <div>
              <b>Item Published</b>:{' '}
              {itemPublishedAt ? (
                <span title={itemPublishedAt.toLocaleString()}>
                  {formatDistanceToNow(itemPublishedAt, {
                    addSuffix: true,
                  })}
                </span>
              ) : (
                'N/A'
              )}
            </div>

            <div>
              <b>Last Bumped</b>:{' '}
              {itemLastBumpedAt ? (
                <span title={itemLastBumpedAt.toLocaleString()}>
                  {formatDistanceToNow(itemLastBumpedAt, {
                    addSuffix: true,
                  })}
                </span>
              ) : (
                'N/A'
              )}
            </div>

            <div>
              <b>Last Price Dropped</b>:{' '}
              {itemLastPriceDroppedAt ? (
                <span title={itemLastPriceDroppedAt.toLocaleString()}>
                  {formatDistanceToNow(itemLastPriceDroppedAt, {
                    addSuffix: true,
                  })}
                </span>
              ) : (
                'N/A'
              )}
            </div>

            <div>
              <b>My Cost</b>: {displayPrice(item.seller_total_cost)}
            </div>

            <div>
              <b>Original List Price</b>:{' '}
              {item.original_list_price ? (
                displayPrice(item.original_list_price)
              ) : (
                <i>N/A</i>
              )}
            </div>

            <div>
              <b>Item Location</b>: {item.location_id || 'N/A'}
            </div>
          </div>
        </Panel>

        <Panel>
          <div className="mb-4 text-lg font-semibold">Value Guide</div>
          <ItemValueGuideInsights item={item} />
        </Panel>

        <NewModalCloseActionButton fluid>Close</NewModalCloseActionButton>
      </SimpleModal>
    </>
  );
}
