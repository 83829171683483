import useAddresses, { prefetchAddresses } from '~/hooks/useAddresses';
import { QueryClient, UseQueryOptions } from '@tanstack/react-query';
import { RailsAddress } from '~/typings/services/rails/session';
import { NextPageContext } from 'next';
import { UseQueryResult } from '@tanstack/react-query';

type AddressContext = 'shipFrom' | 'shipTo';

export async function prefetchAddress(
  ctx: NextPageContext,
  queryClient: QueryClient,
  context: AddressContext,
) {
  const addresses = await prefetchAddresses(ctx, queryClient);

  return addresses?.find(address => {
    if (context === 'shipFrom') {
      return address.flags.ship_from === true;
    }

    if (context === 'shipTo') {
      return address.flags.ship_to === true;
    }
  });
}

export default function useAddress(
  context: AddressContext,
  options?: UseQueryOptions<RailsAddress[]>,
): UseQueryResult<RailsAddress | undefined> {
  const query = useAddresses(options);

  return {
    ...query,
    data: query.data?.find(address => {
      if (context === 'shipFrom') {
        return address.flags.ship_from === true;
      }

      if (context === 'shipTo') {
        return address.flags.ship_to === true;
      }
    }),
  } as UseQueryResult<RailsAddress | undefined>;
}
