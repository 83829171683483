'use client';
import { clsx } from 'clsx';
import React from 'react';
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views';
import {
  CarouselNextButton,
  CarouselPreviousButton,
} from '~/components/ItemDetails/elements';
import MobileOnlyFeatheredOverflowContainer from '~/components/MobileOnlyFeatheredOverflowContainer';
import { Indicator } from '~/components/Swipeable/Elements';
import useCarousel from '~/hooks/useCarousel';

interface Props
  extends Omit<SwipeableViewsProps, 'index' | 'onChangeIndex' | 'ref'> {
  className?: string;
  indicatorColor?: 'white';
  indicatorPosition?: 'bottom-floating';
  showIndicators?: boolean;
  showNextPrevButtons?: boolean;
  thumbnails?: React.ReactNode;
}

const ManagedSwipeableCarouselContainer: React.FC<Props> = ({
  children,
  className,
  indicatorColor,
  indicatorPosition,
  showIndicators,
  showNextPrevButtons,
  thumbnails,
  ...props
}) => {
  const count = React.Children.count(children);

  const { index, goToNextIndex, goToPreviousIndex, setIndex } =
    useCarousel(count);

  return (
    <div className={clsx('relative', className)}>
      <SwipeableViews index={index} onChangeIndex={setIndex} {...props}>
        {children}
      </SwipeableViews>

      {showNextPrevButtons && (
        <>
          <CarouselPreviousButton onClick={goToPreviousIndex} />
          <CarouselNextButton onClick={goToNextIndex} />
        </>
      )}

      {showIndicators && (
        <div
          className={clsx('mt-4 flex w-full justify-center', {
            'absolute bottom-6': indicatorPosition === 'bottom-floating',
          })}
        >
          <div className="bg-true-black/50 flex gap-2 rounded-full px-2 py-1.5">
            {React.Children.map(children, (_, i) => (
              <Indicator
                key={i}
                color={indicatorColor}
                selected={
                  Math.abs(index) % React.Children.count(children) === i
                }
              />
            ))}
          </div>
        </div>
      )}

      {thumbnails && (
        <MobileOnlyFeatheredOverflowContainer className="mt-4 justify-center gap-2">
          {React.Children.map(thumbnails, (thumbnail, i) => (
            <button
              key={i}
              type="button"
              onClick={() => setIndex(i)}
              className={clsx(
                'transition-opacity duration-300',
                index === i ? '' : 'opacity-30',
              )}
            >
              {thumbnail}
            </button>
          ))}
        </MobileOnlyFeatheredOverflowContainer>
      )}
    </div>
  );
};

export default ManagedSwipeableCarouselContainer;
