import { useQuery } from '@tanstack/react-query';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  InitialTableState,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { displayPrice } from 'utils';
import { RailsItemSwapInsights } from '~/api/item';
import { fetchOffersByItemId, OfferByItemId } from '~/api/swaps';
import SwapDetailsLink from '~/components/Links/SwapDetailsLink';
import SimpleReactTable from '~/components/Tables/SimpleReactTable';
import useHasFeature from '~/hooks/useHasFeature';
import { RailsItem } from '~/typings/services/rails/item';
import { RailsSwapDetail } from '~/typings/services/rails/swaps';
import getSwapMargin, { getItemMargin } from '~/utils/getSwapMargin';

export default function ItemOffersTable({
  item,
  swapDetails,
}: {
  item: RailsItem | RailsSwapDetail['item'] | RailsItemSwapInsights['item'];
  swapDetails?: RailsSwapDetail;
}) {
  const hasInsightsFeature = useHasFeature('insights');

  const { data: offers } = useQuery(
    ['offers', item.id],
    () => fetchOffersByItemId(item.id),
    {
      enabled: hasInsightsFeature,
    },
  );

  const filteredOffers = useMemo(
    () =>
      offers?.filter(offer => !offer.seller_offer && !offer.counter_offer) ||
      [],
    [offers],
  );

  const offersTable = useReactTable({
    data: filteredOffers,
    columns: useMemo<ColumnDef<OfferByItemId>[]>(
      () => [
        {
          id: 'created_at',
          accessorFn: row => new Date(row.created_at),
          cell: info => (info.getValue() as Date)?.toLocaleDateString(),
          header: 'Date',
          enableSorting: true,
        },
        {
          id: 'buyer',
          accessorFn: row => `@${row.buyer?.username}`,
          header: 'Buyer',
        },
        {
          accessorKey: 'state',
          header: 'State',
        },
        {
          accessorKey: 'amount',
          header: 'Offer',
          cell: info =>
            displayPrice(info.getValue() as OfferByItemId['amount']),
          enableSorting: true,
        },
        {
          id: 'margin',
          header: 'Margin',
          accessorFn: row =>
            // @ts-expect-error
            swapDetails ? getSwapMargin(swapDetails, row) : getItemMargin(item),
          cell: info => `${info.getValue()}%`,
          enableSorting: true,
        },
        {
          id: 'actions',
          header: () => <span className="sr-only">Actions</span>,
          cell: info =>
            swapDetails?.id === info.row.original.swap_id ? (
              <span></span>
            ) : (
              <SwapDetailsLink
                swapId={info.row.original.swap_id}
                className="text-green-500 underline"
              >
                View
              </SwapDetailsLink>
            ),
        },
      ],
      [swapDetails, item],
    ),
    getRowId: (row: OfferByItemId) => String(row.swap_id) + row.created_at,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: useMemo<InitialTableState>(
      () => ({
        rowSelection:
          swapDetails?.offers.reduce(
            (prev, cur) => ({
              ...prev,
              [String(swapDetails.id) + cur.created_at]: true,
            }),
            {},
          ) || {},
        sorting: [
          {
            id: 'created_at',
            desc: true,
          },
        ],
      }),
      [swapDetails, item],
    ),
  });

  if (!hasInsightsFeature) {
    return null;
  }

  if (filteredOffers.length > 0) {
    return <SimpleReactTable table={offersTable} />;
  } else {
    return (
      <div className="text-slate-green-500 font-semibold italic">
        No recent offers
      </div>
    );
  }
}
