import { RailsSwapDetail } from '~/typings/services/rails/swaps';

export function getLatestOffer(swapDetails?: RailsSwapDetail) {
  return swapDetails?.offers?.[swapDetails.offers.length - 1];
}

export default function getCurrentOffer(swapDetails?: RailsSwapDetail) {
  return swapDetails?.offers?.find(
    offer => swapDetails.current_offer_id === offer.id,
  );
}
