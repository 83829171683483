import fireAnalytics from '~/services/analytics/fireAnalytics';

export type PaywallSource = 'Auto-reject' | 'Insights' | 'Cover photo' | 'Bio';

export function fireVisitedPaywallModal(source: PaywallSource) {
  fireAnalytics('Visited Paywall', source, {
    platform: 'web',
  });
}

export function fireClosedPaywall(source: PaywallSource) {
  fireAnalytics('Closed Paywall', source, {
    platform: 'web',
  });
}

export function fireClickedUpgradeToSidelinePro(source: PaywallSource) {
  fireAnalytics('Clicked Upgrade to Sideline Pro', source, {
    platform: 'web',
  });
}

export function fireClickedPlan(plan: 'pro' | 'pro+') {
  fireAnalytics('Clicked Plan', null, {
    plan,
    platform: 'web',
  });
}

export function fireClickedSubscribe(
  plan: 'pro' | 'pro+',
  cycle: 'monthly' | 'annual',
) {
  fireAnalytics('Clicked Subscribe', null, {
    plan,
    cycle,
    platform: 'web',
  });
}

type InitiatedSubscriptionCheckoutParams = {
  plan: 'pro' | 'pro+';
  cycle: 'monthly' | 'annual';
  has_payment: boolean;
  total: number;
};

export function fireInitiatedSubscriptionCheckout(
  params: InitiatedSubscriptionCheckoutParams,
) {
  fireAnalytics('Initiated Subscription Checkout', null, {
    ...params,
    platform: 'web',
  });
}

type CompletedSubscriptionCheckoutParams = {
  plan: 'pro' | 'pro+';
  cycle: 'monthly' | 'annual';
  payment_method: string;
  total: number;
};

export function fireCompletedSubscriptionCheckout(
  params: CompletedSubscriptionCheckoutParams,
) {
  fireAnalytics('Completed Subscription Checkout', null, {
    ...params,
    platform: 'web',
  });
}

export function fireVisitedManagePlan(
  plan?: 'pro' | 'pro+',
  cycle?: 'monthly' | 'annual',
) {
  fireAnalytics('Visited Manage Plan', null, {
    plan,
    cycle,
    platform: 'web',
  });
}

export function fireClickedCancelSubscription(
  plan: 'pro' | 'pro+',
  cycle: 'monthly' | 'annual',
) {
  fireAnalytics('Clicked Cancel Subscription', null, {
    plan,
    cycle,
    platform: 'web',
  });
}

export function fireCanceledSubscription(
  plan: 'pro' | 'pro+',
  cycle: 'monthly' | 'annual',
) {
  fireAnalytics('Canceled Subscription', null, {
    plan,
    cycle,
    platform: 'web',
  });
}

export function fireUpdatedSubscription(
  previousPlan: 'pro' | 'pro+',
  newPlan: 'pro' | 'pro+',
) {
  fireAnalytics('Updated Subscription', null, {
    previous_plan: previousPlan,
    new_plan: newPlan,
    platform: 'web',
  });
}
