import {
  RailsAddress,
  RailsAddressRequest,
} from '~/typings/services/rails/session';
import { fetcher, makeFetcher } from 'fetcher-session';

export interface RailsRegion {
  id: string;
  name: string;
}

export const fetchAddresses = makeFetcher<RailsAddress[]>(
  () => '/v1/addresses',
);

export const fetchAddressById = makeFetcher<RailsAddress[], [number]>(
  (id: number) => `/v1/addresses/${id}`,
);

export const fetchRegions = makeFetcher<RailsRegion[]>(() => '/v1/regions');

export const fetchSubregions = makeFetcher<RailsRegion[], [string]>(
  (region: string) => `/v1/regions/${region}`,
);

export const deleteAddress = makeFetcher<RailsAddress, [number]>(
  (id: number) => [
    `/v1/addresses/${id}`,
    {
      method: 'delete',
    },
  ],
);

export const postCreateAddress = (address: RailsAddressRequest) =>
  fetcher<RailsAddress>('/v1/addresses', {
    body: { address },
    method: 'post',
  });

export const putUpdateAddress = (id: number, address: RailsAddressRequest) =>
  fetcher<RailsAddress>(`/v1/addresses/${id}`, {
    body: { address },
    method: 'put',
  });
