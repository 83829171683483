import { RailsItem } from '~/typings/services/rails/item';
import { RailsSwapDetail } from '~/typings/services/rails/swaps';
import { qsStringify } from 'utils';

export function itemDetailsToSearchLink(
  item: RailsItem | RailsSwapDetail['item'],
  additional?: Record<string, any>,
) {
  return `/search${qsStringify({
    category: [item.categories?.[0]?.id],
    model: [item.model?.id],
    ...item.details?.reduce((acc, detail) => {
      if (!detail.values) {
        return acc;
      }

      return {
        ...acc,
        [detail.slug]: detail.values.map(value => value.id),
      };
    }, {}),
    ...additional,
  })}`;
}
